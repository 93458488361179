// ページ内アンカーリンクのスクロールエフェクト // Scrolling effect for anchor links within the page
// ヘッダーが固定の場合はheaderHightにヘッダーの高さを設定(PC/SP要設定) // Set header height to headerHight if header is fixed (PC/SP setting required)
jQuery(function ($) {
    var headerHeight = 0; // ヘッダー追従でない場合 // if not header-following
    // var headerHeight = $('header').innerHeight(); // ヘッダー追従の場合 // if header following
    $('a[href^="#"]').click(function () {
        var href = $(this).attr("href");
        var target = $(href == "#" || href == "" ? "html" : href);
        if (target.length > 0) {
            var position = target.offset().top - headerHeight;
            $("html, body").animate({ scrollTop: position }, 550, "swing");
            return false;
        }
    });
});

// ブログサムネイル 中心でトリミング // Blog thumbnail Crop at center
jQuery(function ($) {
    if ($(".obj_image").length > 0) {
        objectFitImages(".obj_image");
    }
});

// ヘッダーの固定エフェクト // Fixed header effect
// jQuery(function($) {
// 	var $header = $('header');
// 	$(window).scroll(function() {
// 		if ($(window).scrollTop() > 50) {
// 			$header.addClass('fixed');
// 		} else {
// 			$header.removeClass('fixed');
// 		}
// 	});
// });

// アコーディオンの起動 // Activate the accordion
jQuery(function ($) {
    if ($("dl.accordion dt").length > 0) {
        $("dl.accordion dt").click(function () {
            $(this).next("dd").slideToggle();
            $(this).toggleClass("open");
        });
    }
});

// ドロワーメニュー // Drawer menu
jQuery(function ($) {
    if ($("#toggle").length > 0) {
        $("#toggle").click(function () {
            $(this).toggleClass("toggle_active");
            $("#drawer_layer").fadeToggle();
            $("body").toggleClass("drawer_open");

            if ($(this).hasClass("toggle_active")) {
                $("nav").addClass("is_open");
                $("nav").removeClass("is_close");
            } else {
                $("nav").removeClass("is_open");
                $("nav").addClass("is_close");
            }
        });

        $("#drawer_layer").click(function () {
            $(this).fadeOut();
            $("#toggle").removeClass("toggle_active");
            $("body").removeClass("drawer_open");
            $("nav").removeClass("is_open");
            $("nav").addClass("is_close");
        });

        // エリア内リンク用 // For links within an area
        $("nav a").on("click", function () {
            // if (window.innerWidth <= 768) {
            if ($("#toggle:visible").length > 0) {
                $("#toggle").click();
            }
        });
    }
});

// scrollHint
window.addEventListener("DOMContentLoaded", function () {
    new ScrollHint(".js-scrollable", {
        i18n: {
            scrollable: "横スクロールできます",
        },
    });
});

document.addEventListener("DOMContentLoaded", function () {
    function adjustHeight() {
        var windowHeight = window.innerHeight;
        var headerHeight = document.querySelector("header").offsetHeight;
        var footerHeight = document.querySelector("footer").offsetHeight;
        var bodyHeight = document.body.scrollHeight;

        var mainElement = document.querySelector(".l-page__main");
        var mainElementHeight = mainElement.scrollHeight;

        var otherElementsHeight = headerHeight + footerHeight;
        var availableHeight = windowHeight - otherElementsHeight;

        if (bodyHeight < windowHeight) {
            mainElement.style.height = availableHeight + "px";
        } else {
            mainElement.style.height = "auto";
        }
    }

    // 初回実行
    adjustHeight();

    // ウィンドウリサイズ時に高さを再調整
    window.addEventListener("resize", adjustHeight);
});

//削除モーダル
document.addEventListener("DOMContentLoaded", function () {
    var deleteButtons = document.querySelectorAll(".js-delete");
    var modal = document.querySelector(".c-modalDelete");
    var confirmButton = document.getElementById("confirmDelete");
    var cancelButton = document.querySelector(".c-modalDelete .close");
    var targetForm = null;

    deleteButtons.forEach(function (button) {
        button.addEventListener("click", function (event) {
            // フォームを特定
            targetForm = event.target.closest("form");
            // モーダルを表示
            modal.style.display = "flex";
        });
    });

    confirmButton.addEventListener("click", function () {
        // モーダルを閉じてフォームを送信
        modal.style.display = "none";
        if (targetForm) {
            targetForm.submit();
        }
    });

    cancelButton.addEventListener("click", function () {
        // モーダルを閉じる
        modal.style.display = "none";
        targetForm = null; // ターゲットフォームをリセット
    });

    // モーダルの外側をクリックしたときにモーダルを閉じる
    window.addEventListener("click", function (event) {
        if (event.target === modal) {
            modal.style.display = "none";
            targetForm = null; // ターゲットフォームをリセット
        }
    });
});

//入力フォームリセット
jQuery(function ($) {
    if ($(".js-reset-form").length > 0) {
        $(".js-reset-form").click(function () {
            clearFormAll();
        });
    }
});
function clearFormAll() {
    for (var i = 0; i < document.forms.length; ++i) {
        clearForm(document.forms[i]);
    }
}
function clearForm(form) {
    for (var i = 0; i < form.elements.length; ++i) {
        clearElement(form.elements[i]);
    }
}
function clearElement(element) {
    switch (element.type) {
        case "hidden":
        case "submit":
        case "reset":
        case "button":
        case "image":
            element.value = "";
            return;
        case "file":
            element.value = "";
            return;
        case "text":
        case "password":
        case "textarea":
            element.value = "";
            return;
        case "checkbox":
        case "radio":
            element.checked = false;
            return;
        case "select-one":
        case "select-multiple":
            element.selectedIndex = 0;
            return;
        default:
    }
}
